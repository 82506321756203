@import url("https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap");

.App {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  background-color: white;
}

@media screen and (max-width: 800px){
  .nav-container {
  width: 95%;
  } 
}

@media screen and (max-width: 500px){
  .nav-container {
  width: 100%;
  } 
}

#logo {
  margin: -2% 0 0 -40%;
  width: 10%;
}

@media screen and (max-width: 500px){
  #logo {
    display: none;
  } 
}

.nav-title a{
  font-family: "Raleway", sans-serif;
  padding-left: 0.5rem;
  color: #d20000;
  font-weight: bold;
  font-size: 1.9rem;
  text-decoration: none;
  margin-top:200px;
}

.nav-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30rem;
  margin-right: 3%;
}

@media screen and (max-width: 500px){
  .nav-right {
    width: 15rem;
  } 
}

a{
  font-family: "Raleway", sans-serif;
    font-size: 1rem;
    color: black;
    text-decoration: none;
    text-align: center;
}

a:hover{
  color: red;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 500px){
  a{
    font-size: 0.7rem;
  } 
}