.signup-container{
    width: 100%;
    background-image: url('https://images.unsplash.com/photo-1560930950-5cc20e80e392?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
    height: 95.5vh;
    background-size: cover;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-position: center center;
}


.signup-panel{
    border: 1px solid green;
    height: 600px;
    width: 441px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    background: white;
    border-radius: 5px;
    
}

@media screen and (max-width: 500px){
    .signup-panel {
        height: 500px;
        width: 300px;
    } 
  }

label{
    font-size: 16px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

img{
    width: 100%;
}

.signupBtn{
    width:100%;
    padding: 2%;
    margin-top: 5%;
    transition-duration: 0.4s;
    font-size: 20px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

@media screen and (max-width: 500px){
    .signupBtn{
        width: 65%;
    } 
  }

.signupBtn:hover {
    background-color: #4CAF50;
    color: white;
}

.signup-panel-btm{
    margin-top: 5%;
    width: 400px;
    align-items: center;
}

.signUpBtn{
    margin-left: 10px;
    transition-duration: 0.4s;
    color: rgb(72, 72, 72);
}