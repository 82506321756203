.listing-form-background {
    background-image: url('https://images.unsplash.com/photo-1560930950-5cc20e80e392?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
    height: 100%;
    background-size: cover;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-position: center center;
    background-attachment: fixed;
}

.form-container {
    box-sizing: border-box;
    background-color: #fff;
    width: 50%;
    margin: 100px auto 50px auto;
    padding: 1em;
    border: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 4px;
}

.form-container * {
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.form-container label {
    margin-bottom: 0.5rem;
    line-height: 1.5;
    font-size: 18px;
}

.form-group {
    width: 97%;
    display: flex;
    flex-direction: column;
}

.form-container input {
    /* font-size: 18px; */
    width: 100%;
    writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    -webkit-appearance: textfield;
    -webkit-rtl-ordering: logical;
    cursor: text;
}

.form-container input, .form-container select {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
    text-transform: none;
}

.form-container select {
    width: 103.4%;
    writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    -webkit-appearance: menulist;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
}

.form-container .my-button {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    height: 40px;

    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    -webkit-appearance: button;
    writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    text-indent: 0px;
    text-shadow: none;
}

.form-container .my-button:hover {
    background-color: #FF5A5F;
    border-color: #FF5A5F;
}

.form-container img {
    width: 30%;
}

.form-container .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6c757d;
    height: 250px;
    margin-bottom: 1rem;
}

.image-wrapper h3 {
    color: #fff;
}

.button-container {
    display: flex;
    justify-content: space-between;
    height: 90px;
}

.optimal-price {
    font-weight: bold;
    font-size: 72px;
    color: #FF5A5F;
}

.cancel-button-container {
    display: flex;
    flex-direction: row-reverse;
}

.info-button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 30px;
    line-height: 1;
    padding: 0;
}

.hood-header {
    display: flex;
}

.neighborhood-modal iframe {
    width: 100%;
}

.data-map-iframe {
    height: 550px;
}

.modal-body {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1024px) {
    .form-container {
        width: 60%;
    }

    .data-map-iframe {
        height: 450px;
    }
}

@media screen and (max-width: 800px) {
    .form-container {
        width: 80%;
    }

    .data-map-iframe {
        height: 450px;
    }
}

@media screen and (max-width: 515px) {
    .form-container {
        width: 90%;
    }
}