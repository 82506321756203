.nextTry {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    margin-bottom:3%;
}

.FormField__Button {
    background-color: rgb(252, 65, 65);
    color: white;
    border: none;
    outline: none;
    padding: 15px 70px;
    font-size: .8em;
    font-weight: 500;
}

.topText{
    color:rgb(129, 129, 129);
}

.containerProfile{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 600px;
    color:white;
    box-shadow: inset 0 0 30px #000000fa;
    background:
    url('https://www.pixel4k.com/wp-content/uploads/2019/01/berlin-capital-of-germany-4k_1547938086.jpg')  center no-repeat ;
background-size: cover;
  
  
}

h2{
    padding-top:2%;
    color:rgb(255, 255, 255);
}

.text{
    color:rgb(255, 255, 255);
    text-decoration: none
}

.editable {
    font-size: 18px;
    color: #fffffffa;
    margin-left: 3%;
    text-shadow: 0 1px 0 #ccc, 
            0 6px 1px rgba(0,0,0,.1),
            0 0 5px rgba(0,0,0,.1),
            0 1px 3px rgba(0,0,0,.3),
            0 3px 5px rgba(0,0,0,.2),
            0 5px 10px rgba(0,0,0,.25),
            0 10px 10px rgba(0,0,0,.2),
            0 20px 20px rgba(0,0,0,.15);
}

.editable button {
    border-radius: 50%;
    background-color: rgba(255,255,255,0.3);
    font-weight: bold;
    border: none;
    height: 43px;
    line-height: 1;
}

.editable button:hover {
    background-color: rgba(255,255,255,0.5);
}

.editable button[editext="edit-button"] {
    background: transparent;
    border: none;
    border-radius: 50%;
}

.editable button[editext="edit-button"]:hover {
    background-color: rgba(255,255,255,0.05);
}

.pics{
    margin-top:5%;
    justify-content:center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 20%;
    box-shadow: 3px 3px 5px 5px rgba(99, 99, 99, 0.267);
  }
  

  .blur{
    
    display: flex;
    justify-content:center;
    

  }
  .boxing{
    filter: drop-shadow(0px 0px 10px rgba(36, 36, 36, 0.774));
    background-color: #57575713;
    width: 70%;
    height: 60%;
    display: flex;
    justify-content:center;
    flex-direction: column;
    flex-wrap: wrap;
    backdrop-filter: blur(1px);
    background-color: rgba(49, 49, 49, 0.295);
    padding: 3%;
    margin-top:1%;
}

.profile-pic-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: steelblue;
}

.profile-pic-modal-body img {
    width: 30%;
    margin: 10px;
}

.profile-pic-modal-body input {
    width: 80%;
    margin-top: 10px;
}

.profile-listings {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
}

@media screen and (max-width: 600px) {
    .profile-listings {
        justify-content: center;
    }
}