@import url(https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.listing-form-background {
    background-image: url('https://images.unsplash.com/photo-1560930950-5cc20e80e392?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
    height: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-attachment: fixed;
}

.form-container {
    box-sizing: border-box;
    background-color: #fff;
    width: 50%;
    margin: 100px auto 50px auto;
    padding: 1em;
    border: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 4px;
}

.form-container * {
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.form-container label {
    margin-bottom: 0.5rem;
    line-height: 1.5;
    font-size: 18px;
}

.form-group {
    width: 97%;
    display: flex;
    flex-direction: column;
}

.form-container input {
    /* font-size: 18px; */
    width: 100%;
    -ms-writing-mode: lr-tb !important;
        writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    -webkit-appearance: textfield;
    -webkit-rtl-ordering: logical;
    cursor: text;
}

.form-container input, .form-container select {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
    text-transform: none;
}

.form-container select {
    width: 103.4%;
    -ms-writing-mode: lr-tb !important;
        writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    -webkit-appearance: menulist;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
}

.form-container .my-button {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    height: 40px;

    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    -webkit-appearance: button;
    -ms-writing-mode: lr-tb !important;
        writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    text-indent: 0px;
    text-shadow: none;
}

.form-container .my-button:hover {
    background-color: #FF5A5F;
    border-color: #FF5A5F;
}

.form-container img {
    width: 30%;
}

.form-container .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6c757d;
    height: 250px;
    margin-bottom: 1rem;
}

.image-wrapper h3 {
    color: #fff;
}

.button-container {
    display: flex;
    justify-content: space-between;
    height: 90px;
}

.optimal-price {
    font-weight: bold;
    font-size: 72px;
    color: #FF5A5F;
}

.cancel-button-container {
    display: flex;
    flex-direction: row-reverse;
}

.info-button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 30px;
    line-height: 1;
    padding: 0;
}

.hood-header {
    display: flex;
}

.neighborhood-modal iframe {
    width: 100%;
}

.data-map-iframe {
    height: 550px;
}

.modal-body {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1024px) {
    .form-container {
        width: 60%;
    }

    .data-map-iframe {
        height: 450px;
    }
}

@media screen and (max-width: 800px) {
    .form-container {
        width: 80%;
    }

    .data-map-iframe {
        height: 450px;
    }
}

@media screen and (max-width: 515px) {
    .form-container {
        width: 90%;
    }
}
.App {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  background-color: white;
}

@media screen and (max-width: 800px){
  .nav-container {
  width: 95%;
  } 
}

@media screen and (max-width: 500px){
  .nav-container {
  width: 100%;
  } 
}

#logo {
  margin: -2% 0 0 -40%;
  width: 10%;
}

@media screen and (max-width: 500px){
  #logo {
    display: none;
  } 
}

.nav-title a{
  font-family: "Raleway", sans-serif;
  padding-left: 0.5rem;
  color: #d20000;
  font-weight: bold;
  font-size: 1.9rem;
  text-decoration: none;
  margin-top:200px;
}

.nav-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30rem;
  margin-right: 3%;
}

@media screen and (max-width: 500px){
  .nav-right {
    width: 15rem;
  } 
}

a{
  font-family: "Raleway", sans-serif;
    font-size: 1rem;
    color: black;
    text-decoration: none;
    text-align: center;
}

a:hover{
  color: red;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 500px){
  a{
    font-size: 0.7rem;
  } 
}
.login-container{
    width: 100%;
    background-image: url('https://images.unsplash.com/photo-1560930950-5cc20e80e392?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
    height: 95.5vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
}

.login-panel{
    height: 600px;
    width: 441px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    align-items: center;
    background: white;
    border-radius: 5px;
}

@media screen and (max-width: 500px){
    .login-panel {
        height: 500px;
        width: 300px;
    } 
  }


label{
    font-size: 16px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

img{
    width: 100%;
}

.loginBtn{
    width:100%;
    padding: 2%;
    margin-top: 5%;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    font-size: 20px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

@media screen and (max-width: 500px){
    .loginBtn{
        width: 65%;
    } 
  }

.loginBtn:hover {
    background-color: #4CAF50;
    color: white;
}

.login-panel-btm{
    margin-top: 5%;
    width: 400px;
    align-items: center;
}

.signUpBtn{
    margin-left: 10px;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    color: rgb(72, 72, 72);
}

.input-panel{
    width: 95%;
    padding: 2%;
    font-size: 16px;
    font-weight: 500;
    color: rgb(72, 72, 72);    
}

@media screen and (max-width: 500px){
    .input-panel{
        width: 60%;
    } 
  }

.signup-container{
    width: 100%;
    background-image: url('https://images.unsplash.com/photo-1560930950-5cc20e80e392?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
    height: 95.5vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
}


.signup-panel{
    border: 1px solid green;
    height: 600px;
    width: 441px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    align-items: center;
    background: white;
    border-radius: 5px;
    
}

@media screen and (max-width: 500px){
    .signup-panel {
        height: 500px;
        width: 300px;
    } 
  }

label{
    font-size: 16px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

img{
    width: 100%;
}

.signupBtn{
    width:100%;
    padding: 2%;
    margin-top: 5%;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    font-size: 20px;
    font-weight: 500;
    color: rgb(72, 72, 72);
}

@media screen and (max-width: 500px){
    .signupBtn{
        width: 65%;
    } 
  }

.signupBtn:hover {
    background-color: #4CAF50;
    color: white;
}

.signup-panel-btm{
    margin-top: 5%;
    width: 400px;
    align-items: center;
}

.signUpBtn{
    margin-left: 10px;
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    color: rgb(72, 72, 72);
}
.listing-container{
    height: 530px;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    align-items: center;
    background: white;
    border-radius: 5px;
    opacity: 0.6;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.listing-container:hover {opacity: 1}

@media screen and (max-width: 815px){
    .listing-container{
        height: 570px;
        width: 210px;
        justify-content: space-between;
    } 
}

@media screen and (max-width: 800px){
    .listing-container{
        height: 500px;
        width: 250px;
    } 
}

@media screen and (max-width: 600px) {
    .listing-container {
        width: 300px;
    }
}

@media screen and (max-width: 500px){
.listing-container{
    height: 530px;
    width: 350px;
    } 
}


.listingImg {
    position: relative;
    width: 100%;
  }
  

.imgPanel{
    height: 180px;
    object-fit: cover;
}

@media screen and (max-width: 800px){
    .imgPanel{
        height: 140px;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px){
    .imgPanel{
        height: 180px;
        object-fit: cover;
    }
}
    

.overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height:0;
    -webkit-transition: .5s ease;
    transition: .5s ease;
}
  
.listingImg:hover .overlay {
    bottom: 0;
    height: 100%;
}
  
.optimalPriceHover {
    white-space: nowrap; 
    color: white;
    font-size: 4rem;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

@media screen and (max-width: 800px){
    .optimalPriceHover {
    font-size: 3rem;
    }
}

@media screen and (max-width: 500px){
    .optimalPriceHover {
    font-size: 5rem;
    }
}


.neighborhoodText{
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.cardInfo{
    font-weight: bold;
    width: 80%;
    margin-top: -20px;
    text-align: left;
}

.optimalPrice{
    margin-top: 3px;
    color: red;
}

@media screen and (max-width: 800px){
    h3{
    font-size: 1.2rem;
    }
}

@media screen and (max-width: 500px){
    h3{
    font-size: 2rem;
    }
}

.cardButtonsDiv{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}


.buttonDelete{
    background-color:black;
    border: none;
    color: white;
    padding: 8px 25px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
} 
.buttonDelete:hover {opacity: 1}

.buttonEdit{
    background-color:black;
    border: none;
    color: white;
    padding: 8px 35px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}
.buttonEdit:hover {opacity: 1}
.nextTry {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 3%;
    margin-bottom:3%;
}

.FormField__Button {
    background-color: rgb(252, 65, 65);
    color: white;
    border: none;
    outline: none;
    padding: 15px 70px;
    font-size: .8em;
    font-weight: 500;
}

.topText{
    color:rgb(129, 129, 129);
}

.containerProfile{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 600px;
    color:white;
    box-shadow: inset 0 0 30px #000000fa;
    background:
    url('https://www.pixel4k.com/wp-content/uploads/2019/01/berlin-capital-of-germany-4k_1547938086.jpg')  center no-repeat ;
background-size: cover;
  
  
}

h2{
    padding-top:2%;
    color:rgb(255, 255, 255);
}

.text{
    color:rgb(255, 255, 255);
    text-decoration: none
}

.editable {
    font-size: 18px;
    color: #fffffffa;
    margin-left: 3%;
    text-shadow: 0 1px 0 #ccc, 
            0 6px 1px rgba(0,0,0,.1),
            0 0 5px rgba(0,0,0,.1),
            0 1px 3px rgba(0,0,0,.3),
            0 3px 5px rgba(0,0,0,.2),
            0 5px 10px rgba(0,0,0,.25),
            0 10px 10px rgba(0,0,0,.2),
            0 20px 20px rgba(0,0,0,.15);
}

.editable button {
    border-radius: 50%;
    background-color: rgba(255,255,255,0.3);
    font-weight: bold;
    border: none;
    height: 43px;
    line-height: 1;
}

.editable button:hover {
    background-color: rgba(255,255,255,0.5);
}

.editable button[editext="edit-button"] {
    background: transparent;
    border: none;
    border-radius: 50%;
}

.editable button[editext="edit-button"]:hover {
    background-color: rgba(255,255,255,0.05);
}

.pics{
    margin-top:5%;
    justify-content:center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 20%;
    box-shadow: 3px 3px 5px 5px rgba(99, 99, 99, 0.267);
  }
  

  .blur{
    
    display: flex;
    justify-content:center;
    

  }
  .boxing{
    -webkit-filter: drop-shadow(0px 0px 10px rgba(36, 36, 36, 0.774));
            filter: drop-shadow(0px 0px 10px rgba(36, 36, 36, 0.774));
    background-color: #57575713;
    width: 70%;
    height: 60%;
    display: flex;
    justify-content:center;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    background-color: rgba(49, 49, 49, 0.295);
    padding: 3%;
    margin-top:1%;
}

.profile-pic-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: steelblue;
}

.profile-pic-modal-body img {
    width: 30%;
    margin: 10px;
}

.profile-pic-modal-body input {
    width: 80%;
    margin-top: 10px;
}

.profile-listings {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
}

@media screen and (max-width: 600px) {
    .profile-listings {
        justify-content: center;
    }
}
