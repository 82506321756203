.listing-container{
    height: 530px;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    background: white;
    border-radius: 5px;
    opacity: 0.6;
    transition: 0.5s;
}
.listing-container:hover {opacity: 1}

@media screen and (max-width: 815px){
    .listing-container{
        height: 570px;
        width: 210px;
        justify-content: space-between;
    } 
}

@media screen and (max-width: 800px){
    .listing-container{
        height: 500px;
        width: 250px;
    } 
}

@media screen and (max-width: 600px) {
    .listing-container {
        width: 300px;
    }
}

@media screen and (max-width: 500px){
.listing-container{
    height: 530px;
    width: 350px;
    } 
}


.listingImg {
    position: relative;
    width: 100%;
  }
  

.imgPanel{
    height: 180px;
    object-fit: cover;
}

@media screen and (max-width: 800px){
    .imgPanel{
        height: 140px;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px){
    .imgPanel{
        height: 180px;
        object-fit: cover;
    }
}
    

.overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height:0;
    transition: .5s ease;
}
  
.listingImg:hover .overlay {
    bottom: 0;
    height: 100%;
}
  
.optimalPriceHover {
    white-space: nowrap; 
    color: white;
    font-size: 4rem;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

@media screen and (max-width: 800px){
    .optimalPriceHover {
    font-size: 3rem;
    }
}

@media screen and (max-width: 500px){
    .optimalPriceHover {
    font-size: 5rem;
    }
}


.neighborhoodText{
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.cardInfo{
    font-weight: bold;
    width: 80%;
    margin-top: -20px;
    text-align: left;
}

.optimalPrice{
    margin-top: 3px;
    color: red;
}

@media screen and (max-width: 800px){
    h3{
    font-size: 1.2rem;
    }
}

@media screen and (max-width: 500px){
    h3{
    font-size: 2rem;
    }
}

.cardButtonsDiv{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}


.buttonDelete{
    background-color:black;
    border: none;
    color: white;
    padding: 8px 25px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
} 
.buttonDelete:hover {opacity: 1}

.buttonEdit{
    background-color:black;
    border: none;
    color: white;
    padding: 8px 35px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}
.buttonEdit:hover {opacity: 1}